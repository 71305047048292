/*-----------------------------------------------------------------------------------

    Template Name: Daxone - eCommerce Bootstrap 4 Template
    Version: 1

-----------------------------------------------------------------------------------
    
    CSS INDEX
    ===================
	
    1. Template default CSS
    2. Header style
    3. Slider style
    4. Banner style
    5. Section title style
    6. Product style
    7. Discount style
    8. Footer style
    9. Overview style
    10. Instagram style
    11. Category style
    12. Deal style
    13. Feature style
    14. Brand logo style
    15. Blog style
    16. Breadcrumb style
    17. Product details style
    18. Blog details style
    19. Sidebar style
    20. Cart style
    21. Login register style
    22. Contect us style
    23. Checkout style
    24. Compare style
    25. My account style
    26. About us style
    
-----------------------------------------------------------------------------------*/
@import 'variabls';
@import 'common'; 
@import 'header';
@import 'slider';
@import 'banner';
@import 'section-title';
@import 'product';
@import 'discount';
@import 'footer';
@import 'overview';
@import 'instagram';
@import 'category';
@import 'deal';
@import 'feature';
@import 'brand-logo';
@import 'blog';
@import 'breadcrumb';
@import 'product-details';
@import 'blog-details';
@import 'sidebar';
@import 'cart';
@import 'login-register';
@import 'contect-us';
@import 'checkout';
@import 'compare';
@import 'my-account';
@import 'about-us';













