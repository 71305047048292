/*-------- 5. Section title style ---------*/

.section-title {
    h2 {
        font-size: 36px;
        font-weight: bold;
        margin-bottom: 21px;
        line-height: 30px;
        @media #{$xs-layout} {
            margin-bottom: 14px;
            font-size: 30px;
            line-height: 26px;
        }
    }
    p {
        width: 41%;
        margin: 0 auto;
        line-height: 26px;
        @media #{$lg-layout} {
            width: 52%;
        }
        @media #{$md-layout} {
            width: 69%;
        }
        @media #{$xs-layout} {
            width: 100%;
        }
        @media #{$sm-layout} {
            width: 95%;
        }
    }
    @media #{$xs-layout} {
        &.mb-40 {
            margin-bottom: 30px;
        }
    }
}

.section-title-2 {
    margin-bottom: 33px;
    @media #{$xs-layout} {
        margin-bottom:25px;
    }
    h2 {
        font-size: 33px;
        font-weight: 500;
        margin-bottom: 9px;
        line-height: 30px;
        color: #292323;
        @media #{$xs-layout} {
            margin-bottom: 14px;
            font-size: 28px;
            margin-bottom: 6px;
            line-height: 26px;
        }
    }
}
.section-title-3 {
    margin-bottom: 47px;
    @media #{$xs-layout} {
        margin-bottom: 25px;
    }
    h2 {
        font-size: 33px;
        font-weight: bold;
        margin-bottom: 22px;
        line-height: 30px;
        color: #292323;
        @media #{$xs-layout} {
            margin-bottom: 14px;
        }
    }
    p {
        font-size: 18px;
        color: #666666;
        letter-spacing: 2px;
        span {
            color: $theme-color;
        }
    }
}
.section-title-4 {
    margin-bottom: 50px;
    @media #{$xs-layout} {
        margin-bottom: 25px;
    }
    h2 {
        font-size: 30px;
        font-weight: bold;
        margin: 0;
        line-height: 25px;
        font-family: $robotoslab;
        @media #{$xs-layout} {
            margin-bottom: 14px;
            font-size: 22px;
            line-height: 20px;
        }
    }
}
.section-title-5 {
    h2 {
        font-size: 26px;
        font-weight: bold;
        margin: 0;
        line-height: 21px;
        color: #333745;
        letter-spacing: .25px;
        text-transform: uppercase;
        @media #{$md-layout} {
            font-size: 19px;
        }
        @media #{$xs-layout} {
            margin-bottom: 14px;
            font-size: 20px;
            line-height: 20px;
        }
        @media #{$sm-layout} {
            font-size: 14px;
            line-height: 20px;
            margin-bottom: 1px;
        }
    }
}

.section-title-6 , .section-title-7 {
    h2 {
        font-size: 33px;
        font-weight: 500;
        margin: 0;
        line-height: 28px;
        color: #292323;
        letter-spacing: .25px;
        @media #{$md-layout} {
            font-size: 30px;
        }
        @media #{$xs-layout} {
            margin-bottom: 14px;
            font-size: 25px;
            line-height: 20px;
            text-align: center;
        }
        @media #{$sm-layout} {
            font-size: 25px;
            line-height: 20px;
            margin-bottom: 1px;
            text-align: inherit;
        }
    }
    @media #{$xs-layout} {
        &.mb-50 {
            margin-bottom: 30px;
        }
    }
}










