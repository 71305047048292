// Font Family
$roboto: 'Roboto', sans-serif;
$poppins: 'Poppins', sans-serif;
$marckscript: 'Marck Script', cursive;
$robotoslab: 'Roboto Slab', serif;
$robotomono: 'Roboto Mono', monospace;


// text Colors ---------------

$white: #ffffff;
$black: #000000;
$grey: #f8f8f8;




// Theme Color
$theme-color: #ff5151;



// Responsive Variables 
$xx-layout : 'only screen and (min-width: 1366px) and (max-width: 1600px)';
$xl-layout : 'only screen and (min-width: 1200px) and (max-width: 1365px)';
$lg-layout : 'only screen and (min-width: 992px) and (max-width: 1199px)';
$md-layout:'only screen and (min-width: 768px) and (max-width: 991px)';
$xs-layout:'only screen and (max-width: 767px)';
$sm-layout: 'only screen and (min-width: 576px) and (max-width: 767px)';
