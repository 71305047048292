/*-------- 16. Breadcrumb style ---------*/

.breadcrumb-area {
    height: 420px;
    display: flex;
    align-items: center;
    @media #{$md-layout} {
        height: 300px;
        background-position: 100%;
    }
    @media #{$xs-layout} {
        height: 280px;
        background-position: 100%;
    }
}

.breadcrumb-content {
    margin: 73px 0 0;
    h2 {
        font-size: 34px;
        text-transform: capitalize;
        font-weight: bold;
        margin: 0 0 10px;
        @media #{$xs-layout} {
            font-size: 23px;
        }
        @media #{$sm-layout} {
            font-size: 28px;
        }
    }
    ul{
        li{
            display: inline-block;
            font-size: 15px;
            font-weight: 400;
            margin-right: 18px;
            text-transform: capitalize;
            position: relative;
            color: $theme-color;
            &::before {
                position: absolute;
                width: 18px;
                height: 1px;
                background-color: #1f2226;
                content: "";
                right: -21px;
                top: 12px;
                z-index: 9;
                transform: rotate(115deg);
            }
            &:last-child::before {
                display: none;
            }
            &:last-child {
                margin-right: 0px;
            }
            a {
                font-weight: 500;
                color: #373737;
                &:hover {
                    color: $theme-color; 
                }
            }
        }
    }
}
















